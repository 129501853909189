<template>
    <div class="w-full">
		<SiteHeader />

		<section class="w-full">
			<div class="w-full h-36 md:h-24 py-12 md:py-0"></div>
			<div class="w-full bg-white">
				<div class="container bg-yellow-500 md:bg-white pt-5">
					<!-- Mobile Menu -->
					<div class="header-menu-btn ">
						<button id="navMenuBtn" class="flex justify-center product-btn w-full text-md text-center text-white font-bold tracking-wider bg-yellow-500 hover:bg-yellow-800 duration-300 px-5 pb-3">
							NAVIGATION MENU &nbsp; <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
						</button>
					</div>
					<div id="navMenu" class="w-full md:hidden bg-white md:flex header-menu text-center md:text-left">
						<div class="w-full bg-yellow-500 font-bold pt-4 pb-4">
							<div class="closeMenu flex justify-center"><span class="text-white pt-1">ClOSE MENU</span>&nbsp;&nbsp;<div class="close-modal">&times;</div></div>
						</div>
						<div class="py-2 lg:pr-4 mt-8 md:mt-0">
							<a href="#sizzling-palace" class="closeMenu01 text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Sizzling Palace
							</a>
						</div>
						<div class="py-2 lg:pr-4">
							<a href="#biryani-boys" class="closeMenu02 text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Biryani Boys
							</a>
						</div>
						<div class="py-2 lg:pr-4">
							<a href="#full-menu" class="closeMenu03 text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Full Menu
							</a>
						</div>
						<div class="py-2 lg:pr-4">
							<a href="#who-we-are" class="closeMenu04 text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Who We Are
							</a>
						</div>
						<div class="py-2 lg:pr-4">
							<a href="#contact" class="closeMenu05 text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Contact Us
							</a>
						</div>
					</div>
					<!-- tablet & Desktop Menu -->
					<div class="w-full hidden lg:block pt-6"></div>
					<div class="w-full hidden md:block bg-white md:flex header-menu text-center md:text-left">
						<div class="py-2 lg:pr-4 mt-8 md:mt-0">
							<a href="#sizzling-palace" class="text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Sizzling Palace
							</a>
						</div>
						<div class="text-md lg:text-lg text-center text-yellow-400 font-medium tracking-wide bg-white hidden md:block lg:pr-4 py-2">|</div>
						<div class="py-2 lg:pr-4">
							<a href="#biryani-boys" class="text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Biryani Boys
							</a>
						</div>
						<div class="text-md lg:text-lg text-center text-yellow-400 font-medium tracking-wide bg-white hidden md:block lg:pr-4 py-2">|</div>
						<div class="py-2 lg:pr-4">
							<a href="#full-menu" class="text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Full Menu
							</a>
						</div>
						<div class="text-md lg:text-lg text-center text-yellow-400 font-medium tracking-wide bg-white hidden md:block lg:pr-4 py-2">|</div>
						<div class="py-2 lg:pr-4">
							<a href="#who-we-are" class="text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Who We Are
							</a>
						</div>
						<div class="text-md lg:text-lg text-center text-yellow-400 font-medium tracking-wide bg-white hidden md:block lg:pr-4 py-2">|</div>
						<div class="py-2 lg:pr-4">
							<a href="#contact" class="text-md lg:text-lg text-center text-gray-800 font-medium tracking-wide bg-white hover:bg-yellow-400 hover:text-white duration-300 px-2 py-2">
								Contact Us
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="w-full">
			<div class="header-section mx-auto">
				<div class="container">
					<div class="w-full hidden lg:block py-12"></div>
					<div class="w-full py-8"></div>
					<!-- <div class="text-center lg:text-left text-lg md:text-2xl leading-snug lg:leading-none text-white pt-12 lg:pb-2 my-auto">
						Affordable halal food rich in authentic Arabic flavour, 
					</div> -->
					<div class="text-center lg:text-left text-2xl md:text-5xl leading-snug lg:leading-tight text-white font-bold pb-12 my-auto">
						A Taste of Middle East<br>within Reach
					</div>
					<div class="w-52 m-auto lg:m-0 pb-6">
						<a href="#contact" class="">
							<div class="w-full text-md text-center text-white font-medium tracking-wider border-2 border-yellow-700 rounded-lg bg-yellow-700 hover:bg-yellow-400 hover:text-yellow-700 duration-300 px-5 py-3">Inquire Now!</div>
						</a>
					</div>
					<div class="block lg:hidden">
						<img src="/images/banner-img.png" class="m-auto" alt="Banner Image" />
					</div>
					<div class="w-full hidden lg:block  py-8"></div>
					<div class="w-full hidden lg:block py-12"></div>
					<div id="sizzling-palace" class="h-0"></div>
					<div class="w-full hidden lg:block py-12"></div>
				</div>
			</div>
		</section>

		<section class="w-full white-bg py-12">
			<div class="container py-8">
				<div class="w-full m-auto">
					<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide uppercase pb-10">
						Sizzling Palace
					</div>
					<!-- <div class="w-full text-center md:text-left font-medium text-gray-600 text-md md:text-lg tracking-wide justify pb-8">
						We are the leading marketing intelligence and research firm that specializes in research insights, program monitoring, and evaluating the implementation of marketing, sales and media services. 
						<br><br>
						We provide clients with accurate information on consumer insights and 3rd party agency performance that aids in strategic decision making and identifying opportunity areas for business-building.
					</div> -->

					<div class="w-full flex flex-wrap">
						<div class="w-full md:w-4/12 pb-6 px-4">
							<img src="/images/sizziling-img/img-01.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							<div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								2liter pitcher <br>iced tea
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 175.00
								</div>
							</div>
							<div class="relative">
								<div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-02.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Beef Caldereta
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 365.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-03.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Dagupan Boneless <br>Bangus
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 195.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-04.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Grilled Chicken
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 129.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-05.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Pompano Fish with Green <br>Salad - Good for 2
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 450.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-06.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Relyenong Bangus
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 365.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-07.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Special Bulalo
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 265.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/sizziling-img/img-08.jpg" class="mb-4" alt="Sizzling Palace Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								T-Bone Steak
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 245.00
								</div>
							 </div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
					</div>
					<div id="biryani-boys" class="h-0"></div>
					
				</div>
			</div>
		</section>


		<section class="w-full bg-gray-100 py-12">
			<div class="container py-8">
				<div class="w-full m-auto">
					<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide uppercase pb-10">
						Biryani Boys
					</div>

					<div class="w-full flex flex-wrap">
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img01.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Beef Biryani Party
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 1450.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img02.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Beef Kabab
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 245.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img03.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Chicken Biryani party
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 1350.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img04.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Falafel
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 285.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img05.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Family Mixed Grill
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 325.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img06.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Green Salad
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 85.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img07.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Hummus
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 150.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img08.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Moutabal
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 150.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
						</div>
						
						<div class="w-full md:w-4/12 pb-6 px-4">
							 <img src="/images/biryani-img/img09.jpg" class="mb-4" alt="Biryani Boys Menu" />
							 <div class="h-32 font-bold text-gray-600 text-md md:text-lg tracking-wide">
								Shawarma
								<div class="font-medium text-gray-600 text-sm tracking-wide pb-3">
									PHP 65.00
								</div>
							</div>
							 <div class="relative">
								 <div class="h-28 bg-yellow-400 yellow-tab"></div>
							</div>
							<div id="full-menu" class="h-0"></div>
						</div>
					</div>
					<!-- <div class="py-12"></div> -->
				</div>
			</div>
		</section>


		<section class="w-full bg-gray-200 py-12">
			<div class="container py-8">
				<div class="w-full m-auto">
					<div class="w-full text-center text-xl md:text-4xl font-bold text-black tracking-wide uppercase pb-10">
						Our Menu
					</div>
					<div class="w-full">
						<div class="w-72 m-auto text-center">
							<a href="/images/full-menu.jpg" target="_blank" class="product-btn w-full text-md text-center text-white font-medium tracking-wider border-2 border-yellow-700 rounded-lg bg-yellow-700 hover:bg-yellow-500 hover:border-yellow-500 duration-300 px-5 py-3">
								View Our Full Menu
							</a>
						</div>
						<!-- <div class="w-72 m-auto">
							<button id="modalBtn01" class="product-btn w-full text-md text-center text-white font-medium tracking-wider border-2 border-yellow-700 rounded-lg bg-yellow-700 hover:bg-yellow-500 hover:border-yellow-500 duration-300 px-5 py-3">
								View Our Full Menu
							</button>
						</div>
						<div id="modal01" class="modal">
							<div class="modal-content">
								<div class="w-full flex justify-end text-right pt-4 pb-4">
									<div class="close01 close-modal">&times;</div>
								</div>
								<div class="w-full">
									<img src="/images/full-menu.jpg" class="mb-4" alt="Our Menu" />
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</section>

		<section class="w-full white-bg py-12">
			<div class="container py-8">
				<div calass="w-full">
					<div class="w-full bg-common-dark py-6 px-6 md:py-8 md:px-8">
						<div class="border-2 border-yellow-100">
							<div class="text-center text-xl md:text-5xl leading-snug lg:leading-tight text-white font-bold pt-10 pb-2 my-auto px-6">
								Free Delivery Within Metro Manila
							</div>
							<div class="text-center text-lg md:text-3xl leading-snug lg:leading-none text-white pb-8 my-auto px-6">
								Minimum of &nbsp;<span class="font-bold">P1,200</span> single receipt purchase.
							</div>
							<div class="text-center font-bold text-md md:text-xl leading-snug lg:leading-none text-white pb-12 my-auto px-6">
								You Can Pay Through GCash or Bank Transfer
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="who-we-are" class="h-0"></div>
			<div class="h-40"></div>
		</section>



		<div id="who-we-are" class="h-0"></div>
		<section class="w-full about-yellow-bg">
			<div class="container relative bottom-20">
				<div class="w-full lg:flex">
					<div class="w-full about-bg lg:w-4/12"></div>
					<div class="w-full about-brown-bg lg:w-8/12 px-6 md:px-12 py-12">
						<div class="w-full text-xl md:text-3xl font-bold text-white tracking-wide uppercase pb-8">
							Who We Are
						</div>
						<div class="font-normal text-white text-md md:text-lg tracking-wide lg:text-justify pb-6">
							Sizzling Palace Specialty Restaurant highlights its Mediterranean food line and unveils its new addition to its food choices. New dishes, new healthy treats for the palates, exciting cuisines to look forward to. 
						</div>
						<div class="font-normal text-white text-md md:text-lg tracking-wide lg:text-justify pb-6">
							Let our Biryani Boys authentic Arabic cuisine satisfy your cravings for delicious, tasty and healthy food. Halal food at its best. Go ahead and taste sumptuous food prepared so distinctive of Mediterranean flavors produced by an array of healthy spices. Experience guiltless dining. 
						</div>
						<div class="font-normal text-white text-md md:text-lg tracking-wide lg:text-justify pb-6">
							So when you dine in Sizzling Palace, check out our special Biryani Boys menu. Get into the adventure of trying out something new from our Sizzling Palace as well as Biryani Boys menus. And, still enjoy your original favorites. No matter what you choose, you achieve all the benefits of great taste and healthy eating. 
						</div>
						<div class="font-normal text-white text-md md:text-lg tracking-wide lg:text-justify pb-6">
							In Sizzling Palace and Biryani Boys, it’s more than just a meal... It is a happy and healthy experience.  
						</div>
					</div>
				</div>
				<div id="contact" class="h-0"></div>
			</div>
		</section>

		<section class="w-full bg-common-dark py-12">
			<div class="container py-8">
				<div class="w-full text-center text-xl md:text-4xl font-bold text-white tracking-wide uppercase pb-10">
					Order Now
				</div>
				<div class="w-full lg:flex">
					<div class="w-full lg:w-5/12 pb-8 lg:pb-0">
						<div class="w-full flex pb-8">
							<div class="w-24">
								<img src="/images/icon-address.png" class="" alt="Address Icon" />
							</div>
							<div class="pt-3">
								<div class="font-bold text-white text-md">
									ADDRESS
								</div>
								<div class="font-medium text-white text-md">
									Basement, Isetann Recto, Manila
								</div>
							</div>
						</div>
						<div class="w-full flex pb-8">
							<div class="w-24">
								<img src="/images/icon-email.png" class="" alt="Email Icon" />
							</div>
							<div class="pt-3">
								<div class="font-bold text-white text-md">
									EMAIL
								</div>
								<a href="mailto:amcsp18@yahoo.com" target="_blank" class="font-medium text-white text-md hover:text-yellow-500 duration-300">
									amcsp18@yahoo.com
								</a>
							</div>
						</div>
						<div class="w-full flex pb-8">
							<div class="w-24">
								<img src="/images/icon-contact.png" class="" alt="Contact Icon" />
							</div>
							<div class="pt-3">
								<div class="font-bold text-white text-md">
									Contact Numbers
								</div>
								<a href="tel:028294116" target="_blank" class="font-medium text-white text-md hover:text-yellow-500 duration-300">
									02 8294 116
								</a>
								<br>
								<a href="tel:09287000232" target="_blank" class="font-medium text-white text-md hover:text-yellow-500 duration-300">
									0928 700 0232
								</a>
								<br>
								<a href="tel:09157000232" target="_blank" class="font-medium text-white text-md hover:text-yellow-500 duration-300">
									0915 700 0232
								</a>
							</div>
						</div>

					</div>
					<div class="lg:w-7/12">
						<InquiryForm />
					</div>
				</div>
			</div>
		</section>
		

        <SiteFooter />
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import InquiryForm from '@/components/InquiryForm.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		InquiryForm,
		SiteFooter,
		CollapseTransition
	}
}
</script>
<style>
	.header-section {
		background-image: url('/images/header-bg-mobile.jpg');
		background-color: #000000;
        @apply bg-center;
        @apply bg-bottom;
		@apply bg-no-repeat;
		background-size: cover !important;
	}

	.about-yellow-bg {
		background-image: url('/images/about-yellow-bg.jpg');
		background-color: #f1891c;
        @apply bg-center;
		background-size: cover !important;
	}

	.about-bg {
		background-image: url('/images/about-bg.jpg');
		background-color: #f1891c;
		@apply bg-center;
		@apply bg-left;
		background-size: cover !important;
		min-height: 320px;
	}

	.about-brown-bg {
		background-image: url('/images/about-brown-bg.jpg');
		background-color: #863e15;
        @apply bg-center;
		background-size: cover !important;
	}

	.bg-common-dark {
		background-image: url('/images/footer-bg.jpg');
		background-color: #863e15;
        @apply bg-center;
		@apply bg-no-repeat;
		background-size: cover !important;
	}

	.white-bg {
		background-image: url('/images/white-bg.jpg');
		background-color: #fff;
        @apply bg-center;
		@apply bg-no-repeat;
		background-size: cover !important;
	}

	.yellow-tab {
		position: absolute;
		bottom: 4rem;
		right: 0.5rem;
		width: 20px;
	}

	.modal {
		width: 100%;
		height: 100%;
		display: none;
		position: fixed;
		z-index: 999;
		padding-top: 100px;
		left: 0;
		top: 0;
		overflow: scroll;
		padding-bottom: 6rem;
		background-color: rgb(0,0,0);
		background-color: rgba(0,0,0,0.4);
	}

	.modal-content {
		width: 90%;
		background-color: #fefefe;
		border-radius: 1.2rem;
		margin: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 42px;
	}

	.close-modal {
		width: 30px;
		height: 30px;
		line-height: 26px !important;
		background-color: #db583a;
		font-size: 30px;
		color: #fff;
		font-family: 'Lato', sans-serif;
		font-weight: 900;
		text-align: center;
		cursor:pointer;
		border-radius: 15px;
		transition: 0.3s;
	}

	.close-modal:hover {
		background-color: #ff9900;
	}

	.header-menu-btn {
		display: block;
	}

	.header-menu {
		position: fixed;
		z-index: 999;
		width: 100%;
		left: 0px;
		top: 0px;
		background-color: #fff;
		height: 100vh;
	}

	@media (min-width: 768px) {
		.header-section {
			background-image: url('/images/header-bg-tablet.jpg');
		}

		.yellow-tab {
			right: 0rem;
			width: 15px;
		}

		.modal-content {
			width: 90%;
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
		.header-menu-btn {
			display: none;
		}

		.header-menu {
			position: relative;
			z-index: 1;
			width: 100%;
			left: 0px;
			top: 0px;
			background-color: #fff;
			height: auto;
		}
    }

	@media (min-width: 1024px) {
		.header-section {
			background-image: url('/images/header-bg.jpg');
		}
		.modal-content {
			width: 80%;
		}
    }

	@media (min-width: 1280px) {
		.container {
			max-width: 1280px !important;
		}
		.yellow-tab {
			right: 2.2rem;
			width: 20px;
		}
	}

	@media (min-width: 1440px) {
		
    }
</style>