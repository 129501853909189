<template>
    <form  @submit.prevent="submit">

        <div class="mx-2">
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.name ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="name_" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.name ? 'text-red-500' : 'text-[#072531]'">Name</label> -->
                    <input id="name" type="text"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.name" placeholder="Name" />
                    <InputError :field="errors.name" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>  
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.email ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="email" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.email ? 'text-red-500' : 'text-[#072531]'">Email</label> -->
                    <input id="email" type="email"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.email" placeholder="Email" />
                    <InputError :field="errors.email" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.contact ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="contact" class="absolute top-0 left-0 font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.contact ? 'text-red-500' : 'text-[#072531]'">Contact Number</label> -->
                    <input id="contact" type="tel"  class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.contact" placeholder="Contact Number" />
                    <InputError :field="errors.contact" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>
            <div class="relative w-full px-2 mb-6">
                <div class="relative mb-2 group font-normal border-[1px] font-formal h-18" :class="errors && errors.contact ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <label for="form-menu" class="text-white pb-2">Menu<span style="color: #ff0000 !important;">*</span></label>
					<select id="form-menu" name="menu" v-model="fields.menu" class="contactform-menu-field" placeholder="Menu">
                        <option class="py-2 px-2" value="" disabled hidden selected>Menu</option></span>
                        <option class="py-2 px-2" value="2liter pitcher iced tea - PHP 175">2liter pitcher iced tea - PHP 175</option>
                        <option class="py-2 px-2" value="Beef Caldereta - PHP 365">Beef Caldereta - PHP 365</option>
                        <option class="py-2 px-2" value="Dagupan Boneless Bangus - PHP 195">Dagupan Boneless Bangus - PHP 195</option>
                        <option class="py-2 px-2" value="Grilled Chicken - PHP 129">Grilled Chicken - PHP 129</option>
                        <option class="py-2 px-2" value="Pompano Fish with Green Salad - Good for 2 - PHP 450">Pompano Fish with Green Salad - Good for 2 - PHP 450</option>
                        <option class="py-2 px-2" value="Relyenong Bangus - PHP 365">Relyenong Bangus - PHP 365</option>
                        <option class="py-2 px-2" value="Special Bulalo - PHP 265">Special Bulalo - PHP 265</option>
                        <option class="py-2 px-2" value="T-Bone Steak - PHP 245">T-Bone Steak - PHP 245</option>
                        <option class="py-2 px-2" value="Beef Biryani Party - PHP 1450">Beef Biryani Party - PHP 1450</option>
                        <option class="py-2 px-2" value="Beef Kabab - PHP 245">Beef Kabab - PHP 245</option>
                        <option class="py-2 px-2" value="Chicken Biryani party - PHP 1350">Chicken Biryani party - PHP 1350</option>
                        <option class="py-2 px-2" value="Falafel - PHP 285">Falafel - PHP 285</option>
                        <option class="py-2 px-2" value="Family Mixed Grill - PHP 325">Family Mixed Grill - PHP 325</option>
                        <option class="py-2 px-2" value="Green Salad - PHP 85">Green Salad - PHP 85</option>
                        <option class="py-2 px-2" value="Hummus - PHP 150">Hummus - PHP 150</option>
                        <option class="py-2 px-2" value="Moutabal - PHP 150">Moutabal - PHP 150</option>
                        <option class="py-2 px-2" value="Shawarma - PHP 65">Shawarma - PHP 65</option>
					</select>
                    <InputError :field="errors.menu" class="ml-2 mt-0 text-[10px] text-red-500 absolute"/>
                </div>
            </div>
            <div class="relative w-full px-2">
                <div class="relative mb-3 peer font-normal border-[1px] font-formal" :class="errors && errors.message ? 'border-red-500' : 'border-[#e0e2e4]'">
                    <!-- <label for="message" class="absolute top-0 left-0  font-normal block mt-1 ml-5 text-[13px]" :class="errors && errors.message ? 'text-red-500' : 'text-[#072531]'">Message</label> -->
                    <textarea id="message"  rows="4" class="w-full px-2 pb-2 p-2 pl-5 bg-white border border-gray-400" v-model="fields.message" placeholder="Message" />
                    <InputError :field="errors.message" class="absolute bottom-0 ml-2 -mt-10 text-[10px] text-red-500"/>
                </div>
            </div>
        </div>

        <div class="w-60 m-auto md:m-0 md:ml-auto py-3">
            <button type="submit" class="w-full text-center font-normal px-8 py-2 mx-auto text-lg text-white rounded-lg tracking-wide btn submit-btn">
                <span>SUBMIT</span>
            </button>
        </div>

    </form>
</template>

<script>
import { InputError, hapiMixins } from '@juno0424/vue2-hapi-inputs'

export default {
    mixins: [hapiMixins],
	components: {InputError},
	name: 'InquiryForm',
    data() {
        return {            
            endpoint: "https://hapiform.sg/api/f17f7f9a-71a1-46de-bccb-4779b0149006",
            redirectTo: "/thank-you/"
        }
    }
}
</script>

<style>
	.submit-btn {
        @apply bg-yellow-400;
        transition: 0.3s;
    }
    .submit-btn:hover {
        @apply bg-yellow-500;
        text-decoration: none;
    }
    .contactform-menu-field {
		width: 100%;
		font-size: 18px;
		color: #a5a5a5 !important;
		letter-spacing: 0.5px;
		border: solid 1px #9e9e9e;
		padding: 10px 12px;
		background-color: #fff !important;
	}
</style>

